import cx from 'classnames';
import { useAtom } from 'jotai';
import { ComponentProps, forwardRef, FunctionComponent } from 'react';

import { navigationOpen } from '../../../state/navigation';
import { useDisableFocus } from '../../providers/disable-focus';
import LoadingBar from '../loading-bar';

export type TVariants =
  | 'DEFAULT'
  | 'PRIMARY'
  | 'CTA'
  | 'DANGER'
  | 'GHOST'
  | 'GHOST-DANGER'
  | 'GHOST-PRIMARY'
  | 'DARK'
  | 'LIGHT-GRAY'
  | 'NO-HOVER'
  | 'ROUNDED';

type ButtonProps = {
  isSmall?: boolean;
  disableFocusOnMenu?: boolean;
  variant?: TVariants;
  disableHeight?: boolean;
  isFullWidth?: boolean;
  isLoading?: boolean;
} & ComponentProps<'button'>;

export const getVariant = (variant: TVariants) => {
  switch (variant) {
    case 'LIGHT-GRAY':
      return 'bg-neutral-50 hover:bg-neutral-80 text-white';
    case 'DARK':
      return 'bg-neutral-80 hover:bg-neutral-70 text-white';
    case 'GHOST':
      return cx(
        'bg-white',
        'text-neutral-80',
        'hover:bg-neutral-10',
        'font-bold',
        ''
      );
    case 'GHOST-DANGER':
      return cx('bg-white', 'hover:bg-red-10', 'text-red-100', 'font-bold', '');
    case 'GHOST-PRIMARY':
      return cx(
        'bg-white',
        'hover:bg-brand-10',
        'text-brand-100',
        'font-bold',
        ''
      );
    case 'CTA':
      return cx(
        'bg-cta-100',
        'text-white',
        'hover:bg-cta-60',
        'hover:text-neutral-80',
        'font-bold',
        ''
      );
    case 'DANGER':
      return cx(
        'block',
        'w-full',
        'transition-colors',
        'duration-200',
        'bg-red-100',
        'text-white',
        'hover:bg-red-60',
        'hover:text-neutral-80',
        'font-bold',
        ''
      );
    case 'PRIMARY':
      return cx(
        'leading-4',
        'p-4',
        'bg-brand-logo',
        'text-white',
        'hover:bg-brand-40',
        'hover:text-neutral-80',
        'focus:text-neutral-80',
        'focus:bg-brand-40'
      );
    case 'ROUNDED':
      return cx(
        'bg-white',
        'leading-4',
        '!px-0',
        '!mr-4',
        'rounded',
        'rounded-full',
        'focus:bg-brand-10',
        'hover:bg-brand-10'
      );
    case 'NO-HOVER':
      return cx('');
    default:
      return cx('focus:bg-brand-10', 'hover:bg-brand-10');
  }
};

const getLoaderColor = (variant: TVariants) => {
  switch (variant) {
    case 'DARK':
      return 'bg-neutral-80';
    case 'GHOST-DANGER':
    case 'DANGER':
      return 'bg-red-100';
    case 'CTA':
      return 'bg-cta-100';
    case 'ROUNDED':
      return 'bg-white';
    case 'NO-HOVER':
    case 'PRIMARY':
    case 'GHOST':
    case 'GHOST-PRIMARY':
    default:
      return 'bg-brand-100';
  }
};

const Button: FunctionComponent<ButtonProps> = forwardRef(
  (
    {
      disableFocusOnMenu = false,
      variant = 'DEFAULT',
      isSmall = false,
      className,
      disableHeight = false,
      isFullWidth = true,
      isLoading = false,
      ...props
    },
    ref
  ) => {
    const disableFocus = useDisableFocus();
    const [menuOpen] = useAtom(navigationOpen);

    const hasFocus = disableFocus || (disableFocusOnMenu && menuOpen);

    return isLoading ? (
      <div className={cx('relative')}>
        <LoadingBar color={getLoaderColor(variant)} />
        <button
          ref={ref}
          className={cx(
            'border-brand-100',
            'text-base',
            !disableHeight && 'h-12',
            isSmall ? 'px-2' : 'px-4',
            'focus:outline-none',
            [getVariant(variant)],
            'disabled:bg-neutral-10',
            'disabled:text-neutral-50',
            'disabled:cursor-default',
            'transition-colors',
            'duration-200',
            { 'block w-full': isFullWidth },
            className
          )}
          tabIndex={hasFocus ? -1 : 0}
          {...props}
        />
      </div>
    ) : (
      <button
        ref={ref}
        className={cx(
          'border-brand-100',
          'text-base',
          !disableHeight && 'h-12',
          isSmall ? 'px-2' : 'px-4',
          'focus:outline-none',
          [getVariant(variant)],
          'disabled:bg-neutral-10',
          'disabled:text-neutral-50',
          'disabled:cursor-default',
          'transition-colors',
          'duration-200',
          { 'block w-full': isFullWidth },
          className
        )}
        tabIndex={hasFocus ? -1 : 0}
        {...props}
      />
    );
  }
);

Button.displayName = 'Button';

export default Button;
